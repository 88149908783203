import { Field } from 'Util/Query';
/** @namespace Pwa/Query/AmastyRelatedProducts/Query/AmastyRelatedProductsQuery */
export class AmastyRelatedProductsQuery {
    getAmastyRelatedProducts(uid, position) {
        const query = new Field('amMostviewedGroups')
            .addArgument('uid', 'String!', uid)
            .addArgument('position', 'String', position)
            .addField(this._getAmastyRelatedProductsFields());

        return query;
    }

    _getAmastyRelatedProductsFields() {
        return this._getAmastyRelatedProductsItemsFields();
    }

    _getAmastyRelatedProductsItemsFields() {
        return new Field('items').addFieldList([
            'block_title',
            'position',
            this._getAmastyRelatedProductsItemsFieldsData(),
        ]);
    }

    _getAmastyRelatedProductsItemsFieldsData() {
        return new Field('items').addFieldList([
            'deliverer_available',
            'discount_display',
            'id',
            'name',
            'product_unit',
            'uid',
            'sku',
            'type_id',
            'stock_status',
            'salable_qty',
            'total_saves',
            'url',
            'product_unit',
            'vp_items',
            this._getOmnibusField(),
            this._getStockAvailability(),
            this._getAlternativeHoverImageField(),
            this._getProductImageField(),
            this._getLabelsField(),
            this._getPriceRangeField(),
            this._getProductSmallField(),
        ]);
    }

    _getStockAvailability() {
        return new Field('stock_availability').addFieldList(this._getStockAvailabilityFields());
    }

    _getStockAvailabilityFields() {
        return ['label', 'qty'];
    }

    _getAlternativeHoverImageField() {
        return new Field('alternative_hover_image').addFieldList(this._getAlternativeHoverImageFields());
    }

    _getAlternativeHoverImageFields() {
        return ['path', 'url'];
    }

    _getLabelsField() {
        return new Field('labels').addFieldList(this._getLabelsFields());
    }

    _getLabelsFields() {
        return ['code', 'label_type', 'image', 'url', 'class', 'text', 'text_color', 'text_background_color'];
    }

    _getPriceRangeField() {
        return new Field('price_range').addFieldList(this._getPriceRangeFields());
    }

    _getPriceRangeFields() {
        return [this._getMinimalPriceField(), this._getMaximalPriceField()];
    }

    _getMinimalPriceFields() {
        return [this._getDiscountField(), this._getFinalPriceField(), this._getRegularPriceField()];
    }

    _getMinimalPriceField() {
        return new Field('minimum_price').addFieldList(this._getMinimalPriceFields());
    }

    _getMaximalPriceField() {
        return new Field('maximum_price').addFieldList(this._getMinimalPriceFields());
    }

    _getDiscountField() {
        return new Field('discount').addField('amount_off').addField('percent_off');
    }

    _getFinalPriceField() {
        return new Field('final_price').addField('currency').addField('value');
    }

    _getRegularPriceField() {
        return new Field('regular_price').addField('currency').addField('value');
    }

    _getProductImageField() {
        return new Field('image').addFieldList(this._getProductThumbnailFields());
    }

    _getProductThumbnailFields() {
        return ['path', 'url'];
    }

    _getProductSmallField() {
        return new Field('small_image').addFieldList(this._getProductSmallFields());
    }

    _getProductSmallFields() {
        return this._getProductThumbnailFields();
    }

    _getOmnibusField() {
        return new Field('lowest_price').addFieldList(this._getOmnibusFields());
    }

    _getOmnibusFields() {
        return ['value_incl_tax'];
    }
}

export default new AmastyRelatedProductsQuery();
